export const colors = [
  'rgba(136, 132, 216, 0.7)',  // purple
  'rgba(130, 202, 157, 0.7)',  // light green
  'rgba(255, 198, 88, 0.7)',   // yellow-orange
  'rgba(208, 237, 87, 0.7)',   // lime green
  'rgba(164, 222, 108, 0.7)',  // light green
  'rgba(216, 136, 136, 0.7)',  // light red
  'rgba(132, 216, 216, 0.7)',  // teal
  'rgba(216, 141, 132, 0.7)',  // coral
  'rgba(132, 216, 141, 0.7)',  // mint
  'rgba(216, 216, 216, 0.7)',  // gray
  'rgba(255, 105, 180, 0.7)',  // pink
  'rgba(124, 252, 0, 0.7)',    // green
  'rgba(135, 206, 250, 0.7)',  // light sky blue
  'rgba(240, 128, 128, 0.7)',  // light coral
  'rgba(255, 165, 0, 0.7)',    // orange
  'rgba(147, 112, 219, 0.7)',  // medium purple
  'rgba(255, 182, 193, 0.7)',  // light pink
  'rgba(173, 216, 230, 0.7)',  // light blue
  'rgba(152, 251, 152, 0.7)',  // pale green
  'rgba(100, 149, 237, 0.7)',  // cornflower blue
];