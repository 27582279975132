import React, { useContext, useState } from 'react';
import { BrowserRouter as Router, Link, Route, Routes, Navigate } from 'react-router-dom';
import { AuthContext } from './context/AuthContext'; // Use AuthContext from context
import PrivateRoute from './components/PrivateRoute';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import ResetPassword from './pages/ResetPassword';
import Summary from './components/Summary';
import Comparison from './components/Comparison';
import FileManager from './components/FileManager';
import WarehouseConfig from './components/WarehouseConfig';
import Footer from './components/Footer';
import LanguageSelector from './components/LanguageSelector';
import { AppBar, Button, Container, IconButton, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useTranslation } from 'react-i18next';
import Members from "./components/Members";

const App = () => {
  const { isAuthenticated, logout } = useContext(AuthContext) || {}; // Safe destructure to prevent errors
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Router>
      <AppBar position="static">
        <Toolbar>
          {isAuthenticated ? (
            <>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleMenuOpen}
                sx={{ display: { xs: 'block', md: 'none' } }}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" sx={{ flexGrow: 1, display: { xs: 'none', md: 'block' } }}>
                <Button color="inherit" component={Link} to="/summary">
                  {t('menu.summary')}
                </Button>
                <Button color="inherit" component={Link} to="/comparison">
                  {t('menu.comparison')}
                </Button>
                <Button color="inherit" component={Link} to="/file-manager">
                  {t('menu.fileManager')}
                </Button>
                <Button color="inherit" component={Link} to="/warehouse-config">
                  {t('menu.warehouseConfig')}
                </Button>
                <Button color="inherit" component={Link} to="/members">
                  {t('menu.members')}
                </Button>
              </Typography>
              <Button color="inherit" onClick={logout}>
                {t('menu.logout')}
              </Button>
              <LanguageSelector />
            </>
          ) : (
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              Bioksan Sales Management
            </Typography>
          )}
        </Toolbar>
      </AppBar>
      {isAuthenticated && (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          sx={{ display: { xs: 'block', md: 'none' } }}
        >
          <MenuItem onClick={handleMenuClose} component={Link} to="/summary">
            {t('menu.summary')}
          </MenuItem>
          <MenuItem onClick={handleMenuClose} component={Link} to="/comparison">
            {t('menu.comparison')}
          </MenuItem>
          <MenuItem onClick={handleMenuClose} component={Link} to="/warehouse-config">
            {t('menu.warehouseConfig')}
          </MenuItem>
          <MenuItem onClick={handleMenuClose} component={Link} to="/file-manager">
            {t('menu.fileManager')}
          </MenuItem>
          <MenuItem onClick={handleMenuClose} component={Link} to="/members">
            {t('menu.members')}
          </MenuItem>
        </Menu>
      )}
      <Container>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route
            path="/summary"
            element={
              <PrivateRoute>
                <Summary />
              </PrivateRoute>
            }
          />
          <Route
            path="/comparison"
            element={
              <PrivateRoute>
                <Comparison />
              </PrivateRoute>
            }
          />
          <Route
            path="/file-manager"
            element={
              <PrivateRoute>
                <FileManager />
              </PrivateRoute>
            }
          />
          <Route
            path="/warehouse-config"
            element={
              <PrivateRoute>
                <WarehouseConfig />
              </PrivateRoute>
            }
          />
          <Route
            path="/members"
            element={
              <PrivateRoute>
                <Members />
              </PrivateRoute>
            }
          />
          <Route path="*" element={<Navigate to={isAuthenticated ? '/summary' : '/login'} />} />
        </Routes>
      </Container>
      <Footer />
    </Router>
  );
};

export default App;