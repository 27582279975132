import React, { useState } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import axios from '../services/api';
import {TextField, Button, Box, Typography, Alert, Snackbar} from '@mui/material';

const SignUp = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState(
    {
      firstName: '',
      lastName: '',
      email: '',
      password: ''
    });

  const [alert, setAlert] = useState({ open: false, message: '', severity: 'success' });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('/auth/signup', formData);
      setAlert({ open: true, message: '' +
          'Please check your email to verify your account',
        severity: 'success' });
    } catch (error) {
      setAlert({ open: true, message: error.response.data.message, severity: 'error' });
    }
  };

  return (
    <Box sx={{ maxWidth: 400, mx: 'auto', mt: 4, marginBottom: "20px" }}>
      <Typography variant="h4" gutterBottom>
        Sign Up
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          margin="normal"
          label="First Name"
          name="firstName"
          onChange={handleChange}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Last Name"
          name="lastName"
          onChange={handleChange}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Email"
          name="email"
          onChange={handleChange}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Password"
          name="password"
          type="password"
          onChange={handleChange}
        />
        <Button
          disabled={!formData.firstName && !formData.lastName && !formData.email && !formData.password}
          fullWidth variant="contained" color="primary" type="submit" sx={{ mt: 2 }}>
          Sign Up
        </Button>
      </form>
      <Typography variant="body2" sx={{ mt: 2, textAlign: 'center' }}>
        Already have an account?{' '}
        <Link to="/login" style={{ textDecoration: 'none', color: '#1976d2' }}>
          Log In
        </Link>
      </Typography>
      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={() => setAlert({ ...alert, open: false })}
      >
        <Alert onClose={() => setAlert({ ...alert, open: false })} severity={alert.severity}>
          {alert.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default SignUp;