import React from 'react';
import { Box, Typography } from '@mui/material';

const Footer = () => (
    <Box component="footer" sx={{ textAlign: 'center', padding: '1rem', background: '#f1f1f1', marginTop: 'auto' }}>
        <Typography variant="body2">
            © 2024 Sales Summary App. Made with ❤️ by an anonymous person.
        </Typography>
    </Box>
);

export default Footer;