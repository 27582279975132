// src/components/LanguageSelector.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';

const LanguageSelector = () => {
    const { i18n } = useTranslation();

    const handleChange = (event) => {
        const selectedLanguage = event.target.value;
        i18n.changeLanguage(selectedLanguage);
        localStorage.setItem('i18nextLng', selectedLanguage);
    };

    return (
        <FormControl variant="outlined" sx={{ minWidth: 120, marginLeft: 2 }}>
            <InputLabel>Language</InputLabel>
            <Select
                value={i18n.language}
                onChange={handleChange}
                label="Language"
            >
                <MenuItem value="en">English</MenuItem>
                <MenuItem value="pt">Português</MenuItem>
                <MenuItem value="es">Español</MenuItem>
            </Select>
        </FormControl>
    );
};

export default LanguageSelector;