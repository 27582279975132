import React, {useEffect, useState} from 'react';
import axios from '../services/api';
import {useTranslation} from 'react-i18next';
import {
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    Link,
    List,
    ListItem,
    MenuItem,
    Paper,
    TextField,
    Typography
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const FileManager = () => {
    const { t, i18n } = useTranslation();
    const [files, setFiles] = useState([]);
    const [year, setYear] = useState('2024');
    const [month, setMonth] = useState('');
    const [warehouse, setWarehouse] = useState('');
    const [warehouses, setWarehouses] = useState([]);
    const [filesToUpload, setFilesToUpload] = useState([]);
    const [loading, setLoading] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [open, setOpen] = useState(false);

    const fetchFiles = async () => {
        setLoading(true);
        try {
            const response = await axios.get('/storage/files', { params: { year, month } });
            setFiles(response.data);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const fetchWarehouses = async () => {
        setLoading(true);
        try {
            const response = await axios.get('/storage/warehouses');
            setWarehouses(response.data);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const fetchSuggestions = async (warehouse, language) => {
        setLoading(true);
        try {
            const response = await axios.get('/storage/suggestions', { params: { language } });
            setSuggestions(response.data[warehouse] || []);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleUpload = async () => {
        setOpen(false);
        setLoading(true);
        const formData = new FormData();
        filesToUpload.forEach((file) => {
            formData.append('files', file);
        });

        try {
            await axios.post(`/storage/upload?year=${year}&month=${month}&warehouse=${warehouse}`, formData);
            fetchFiles();
            setFilesToUpload([]);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (fileName) => {
        setLoading(true);
        try {
            await axios.delete(`/storage/delete?year=${year}&month=${month}`, {
                data: [fileName]
            });
            fetchFiles();
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleWarehouseChange = (e) => {
        const selectedWarehouse = e.target.value;
        setWarehouse(selectedWarehouse);
        fetchSuggestions(selectedWarehouse, i18n.language);
    };

    useEffect(() => {
        fetchFiles();
        fetchWarehouses();
    }, [year, month]);

    const isUploadDisabled = !(year && month && warehouse && filesToUpload.length > 0);

    const handleFileSelection = (e) => {
        const selectedFiles = Array.from(e.target.files);
        const filteredFiles = selectedFiles.filter(file => file.name.endsWith('.xls') || file.name.endsWith('.xlsx'));
        setFilesToUpload(filteredFiles);
    };

    const renderSuggestion = (suggestion) => {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        const parts = suggestion.split(urlRegex);
        return parts.map((part, index) => {
            if (part.match(urlRegex)) {
                return (
                    <Link href={part} target="_blank" rel="noopener noreferrer" key={index}>
                        {part}
                    </Link>
                );
            }
            return part;
        });
    };

    const handleOpenDialog = () => {
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setOpen(false);
    };

    const handleDownloadLocale = async () => {
        try {
            const response = await axios.get('/storage/download-locale-spreadsheet', { responseType: 'blob' });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'locale-spreadsheet.xlsx');
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error('Error downloading the file', error);
        }
    };

    const handleUploadLocale = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        const formData = new FormData();
        formData.append('file', file);

        try {
            await axios.post('/storage/upload-locale-spreadsheet', formData);
            alert('File uploaded successfully');
        } catch (error) {
            console.error('Error uploading the file', error);
        }
    };

    return (
      <Box sx={{ margin: 4 }}>
          <Typography variant="h4" gutterBottom>
              {t('fileManager')}
          </Typography>
          <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    select
                    label={t('year')}
                    value={year}
                    onChange={(e) => setYear(e.target.value)}
                    variant="outlined"
                    fullWidth
                  >
                      {[2022, 2023, 2024, 2025].map((yearOption) => (
                        <MenuItem key={yearOption} value={yearOption}>
                            {yearOption}
                        </MenuItem>
                      ))}
                  </TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    select
                    label={t('month')}
                    value={month}
                    onChange={(e) => setMonth(e.target.value)}
                    variant="outlined"
                    fullWidth
                  >
                      <MenuItem value=""></MenuItem>
                      {['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'].map(
                        (monthOption) => (
                          <MenuItem key={monthOption} value={monthOption}>
                              {monthOption}
                          </MenuItem>
                        )
                      )}
                  </TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    select
                    label={t('warehouse')}
                    value={warehouse}
                    onChange={handleWarehouseChange}
                    variant="outlined"
                    fullWidth
                  >
                      {warehouses.map((warehouseOption) => (
                        <MenuItem key={warehouseOption} value={warehouseOption}>
                            {warehouseOption}
                        </MenuItem>
                      ))}
                  </TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                  <Button
                    variant="contained"
                    component="label"
                    sx={{ backgroundColor: '#4caf50', color: '#fff' }}
                    fullWidth
                    disabled={!(year && month && warehouse)}
                  >
                      {t('selectFiles')}
                      <input type="file" accept=".xls,.xlsx" multiple hidden onChange={handleFileSelection} />
                  </Button>
              </Grid>
          </Grid>
          <Grid container spacing={2} mt={4}>
              <Grid item xs={12} sm={6} md={3}>
                  <Button
                    variant="contained"
                    sx={{ backgroundColor: '#2196f3', color: '#fff' }}
                    fullWidth
                    onClick={handleDownloadLocale}
                  >
                      {t('downloadLocaleSpreadSheet')}
                  </Button>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                  <label htmlFor="upload-locale-input">
                      <Button
                        variant="contained"
                        sx={{ backgroundColor: '#f50057', color: '#fff' }}
                        fullWidth
                        component="span"
                      >
                          {t('uploadLocaleSpreadSheet')}
                      </Button>
                  </label>
                  <input
                    id="upload-locale-input"
                    type="file"
                    accept=".xls,.xlsx"
                    hidden
                    onChange={handleUploadLocale} // Use onChange instead of onClick for file input
                  />
              </Grid>
          </Grid>
          <Grid item xs={12} mt={4}>
              <Card>
                  <CardContent>
                      <Typography variant="h6">{t('suggestionsTitle')}</Typography>
                      {suggestions.length > 0 ? (
                        <ul>
                            {suggestions.map((suggestion, index) => (
                              <li key={index}>{renderSuggestion(suggestion)}</li>
                            ))}
                        </ul>
                      ) : (
                        <Typography>{t('noSuggestions')}</Typography>
                      )}
                  </CardContent>
              </Card>
          </Grid>
          <Box mt={4}>
              <Typography variant="h6">{t('selectedFiles')}</Typography>
              <List>
                  {filesToUpload.map((file, index) => (
                    <ListItem key={index}>
                        {file.name}
                    </ListItem>
                  ))}
              </List>
          </Box>
          <Grid item xs={12} sm={6} md={3} mt={2}>
              <Button
                variant="contained"
                sx={{ backgroundColor: '#4caf50', color: '#fff' }}
                onClick={handleOpenDialog}
                fullWidth
                disabled={isUploadDisabled}
              >
                  {t('upload')}
              </Button>
          </Grid>
          <Box mt={4}>
              <Typography variant="h5">{t('files')}</Typography>
              {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" mt={4}>
                    <CircularProgress />
                </Box>
              ) : (
                <Paper elevation={3} sx={{ padding: 2 }}>
                    <ul>
                        {files.map((file, index) => (
                          <li key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '8px' }}>
                              {file}
                              <IconButton aria-label="delete" onClick={() => handleDelete(file)}>
                                  <DeleteIcon />
                              </IconButton>
                          </li>
                        ))}
                    </ul>
                </Paper>
              )}
          </Box>
          <Dialog open={open} onClose={handleCloseDialog}>
              <DialogTitle>{t('confirmation')}</DialogTitle>
              <DialogContent>
                  <DialogContentText>
                      {t('confirmUpload', { warehouse, year, month })}
                  </DialogContentText>
              </DialogContent>
              <DialogActions>
                  <Button onClick={handleCloseDialog} color="primary">
                      {t('cancel')}
                  </Button>
                  <Button onClick={handleUpload} color="primary" autoFocus>
                      {t('confirm')}
                  </Button>
              </DialogActions>
          </Dialog>
      </Box>
    );
};

export default FileManager;