import React, { useState } from 'react';
import axios from '../services/api';
import {TextField, Button, Box, Typography, Alert, Snackbar} from '@mui/material';

const ResetPassword = () => {
  const [userEmail, setEmail] = useState('');
  const [alert, setAlert] = useState({ open: false, message: '', severity: 'success' });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('/auth/request/reset-password', { userEmail });
      setAlert({ open: true, message: 'Password reset email sent successfully', severity: 'success' });
    } catch (error) {
      setAlert({ open: true, message: error.response.data.message, severity: 'error' });
    }
  };

  return (
    <Box sx={{ maxWidth: 400, mx: 'auto', mt: 4, marginBottom: "20px" }}>
      <Typography variant="h4">Reset Password</Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          margin="normal"
          label="Email"
          name="userEmail"
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button
          disabled={!userEmail}
          fullWidth variant="contained" color="primary" type="submit">
          Reset Password
        </Button>
      </form>
      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={() => setAlert({ ...alert, open: false })}
      >
        <Alert onClose={() => setAlert({ ...alert, open: false })} severity={alert.severity}>
          {alert.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ResetPassword;