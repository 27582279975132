import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_HOST;

const api = axios.create({
    baseURL: API_BASE_URL + '/api/v1'
});

api.interceptors.request.use(
  (config) => {
      const token = localStorage.getItem('token');
      if (token) {
          config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
  },
  (error) => Promise.reject(error)
);

export default api;