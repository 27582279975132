import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
    en: {
        translation: {
            "menu": {
                "summary": "Summary",
                "fileManager": "File Manager",
                "comparison": "Comparison",
                "warehouseConfig": "Warehouse",
                "members": "Users and Roles",
                "logout": "Logout"
            },
            "salesSummary": "Sales Summary",
            "year": "Year",
            "month": "Month",
            "months": "Months",
            "city": "City",
            "view": "View",
            "warehouse": "Warehouse",
            "pharmacy": "Pharmacy",
            "zipcode": "Zipcode",
            "total": "Total",
            "fileManager": "Spreadsheets",
            "selectFiles": "Select Files",
            "selectedFiles": "Selected Files",
            "upload": "Upload",
            "files": "Files",
            "warehouseSales": "Warehouse Sales",
            "pharmacySales": "Pharmacy Sales",
            "productSales": "Product Sales",
            "suggestionsTitle": "Suggestions",
            "noSuggestions": "No suggestions available.",
            "comparison": "Comparison",
            "fromUntil": "From {{from}} Until {{until}}",
            "January": "January",
            "February": "February",
            "March": "March",
            "April": "April",
            "May": "May",
            "June": "June",
            "July": "July",
            "August": "August",
            "September": "September",
            "October": "October",
            "November": "November",
            "December": "December",
            "warehouseConfigs": "Warehouse Configurations",
            "addNewConfig": "Add New Configuration",
            "name": "Name",
            "email": "Email",
            "isAdmin": "Administrator",
            "active": "Active",
            "accountVerified": "Account Verified",
            "qtdSheets": "Quantity of Sheets",
            "productNameColumn": "Product Name Column",
            "pharmacyNameColumn": "Pharmacy Name Column",
            "quantitySoldColumn": "Quantity Sold Column",
            "cityColumn": "City Column",
            "zipCodeColumn": "Zip Code Column",
            "warehouseConfig": "Warehouse Configurations",
            "enterSingleLetter": "Enter a single letter",
            "add": "Add",
            "addSuccess": "Configuration added successfully",
            "addError": "Error adding configuration",
            "selectPeriod": "Select Period",
            "salesByProduct": "Sales by Product",
            "fromMonth": "From Month",
            "toMonth": "To Month",
            "currentConfigs": "Current Configurations",
            "totalSold": "Total Sold",
            "confirmation": "Upload File",
            "confirmUpload": "Want to upload files to warehouse {{warehouse}} for the period {{year}}/{{month}}?",
            "confirm": "Confirm",
            "cancel": "Cancel",
            "salesByWarehouse": "Sales by Warehouse",
            "topCities": "Top Cities",
            "analyze": "Analyse",
            "selectAllWarehouses": "All Warehouses",
            "moreThan" : "More than",
            "nameOf": "Name of",
            "quantitySalesBy" : "Quantity of Sales by",
            "filterByNumberOfSales": "Filter by number of sales",
            "filterByName": "Filter by name",
            "all": "All",
            "quantity": "Quantity",
            "sales": "Sales",
            "percentPrevMonth": "Percentage of sales compared to the previous month",
            "quantityPrevMonth": "Quantity of sales compared to the previous month",
            "prevMonthChange": "Change from previous month",
            "gainsAndLosses": "Gains and Losses",
            "statisticsComparison": "Statistics Comparison",
            "compareEachMonthTip": "Compare each month with the previous one",
            "gainAndLossesOver": "Gains and Losses over ",
            "pnl": "P&L",
            "momFull": "Month over Month",
            "sold": "Sold",
            "downloadLocaleSpreadSheet": "Download Locale Spreadsheet",
            "uploadLocaleSpreadSheet": "Upload Locale Spreadsheet",
            "cacheReseted": "Cache reseted successfully",
            "reset": "Reset",
            "cacheResetMessage": "Not seeing the correct count? Try refreshing your data. This won’t change any of your previous or current data — just clears temporary storage to help show the right information.",
            "resetSelectedPeriod": "Reset Selected Period",
            "hardReset": "Reset All",
            "showProductName": "Show Product Name",
            "showQuantity": "Show Quantity",
            "showWarehouseName": "Show Warehouse Name",
            "hideLowQuantity": "Hide Low Quantities",
            "hideIrregularProducts": "Hide Irregular Products",
            "overview": "Overview",
            "selectAll": "Select All",
            "unselectAll": "Unselect All",
            "statistics": "Statistics",
            "usersAndRoles": "Users and Roles",
        }
    },
    pt: {
        translation: {
            "menu": {
                "summary": "Resumo",
                "fileManager": "Planilhas",
                "comparison": "Comparação",
                "warehouseConfig": "Armazenistas",
                "members": "Usuários e Permissões",
                "logout": "Sair"
            },
            "salesSummary": "Resumo de Vendas",
            "year": "Ano",
            "month": "Mês",
            "months": "Meses",
            "city": "Cidade",
            "view": "Vista",
            "warehouse": "Armazém",
            "pharmacy": "Farmácia",
            "zipcode": "Código Postal",
            "total": "Total",
            "fileManager": "Gerenciador de Arquivos",
            "selectFiles": "Selecionar Arquivos",
            "selectedFiles": "Arquivos Selecionados",
            "upload": "Upload",
            "files": "Arquivos",
            "warehouseSales": "Vendas por Armazém",
            "pharmacySales": "Vendas por Farmácia",
            "productSales": "Vendas por Produto",
            "suggestionsTitle": "Sugestões",
            "noSuggestions": "Nenhuma sugestão disponível.",
            "comparison": "Comparação",
            "fromUntil": "De {{from}} Até {{until}}",
            "January": "Janeiro",
            "February": "Fevereiro",
            "March": "Março",
            "April": "Abril",
            "May": "Maio",
            "June": "Junho",
            "July": "Julho",
            "August": "Agosto",
            "September": "Setembro",
            "October": "Outubro",
            "November": "Novembro",
            "December": "Dezembro",
            "warehouseConfigs": "Configurações de Armazéns",
            "addNewConfig": "Adicionar Nova Configuração",
            "name": "Nome",
            "email": "Email",
            "isAdmin": "Administrador",
            "active": "Ativo",
            "accountVerified": "Conta Verificada",
            "qtdSheets": "Quantidade de Planilhas",
            "productNameColumn": "Coluna do Nome do Produto",
            "pharmacyNameColumn": "Coluna do Nome da Farmácia",
            "quantitySoldColumn": "Coluna de Quantidade Vendida",
            "cityColumn": "Coluna da Cidade",
            "zipCodeColumn": "Coluna do Código Postal",
            "warehouseConfig": "Configurações de Armazéns",
            "enterSingleLetter": "Digite uma única letra",
            "add": "Adicionar",
            "addSuccess": "Configuração adicionada com sucesso",
            "addError": "Erro ao adicionar configuração",
            "selectPeriod": "Selecione o Período",
            "salesByProduct": "Vendas por Produto",
            "fromMonth": "De Mês",
            "toMonth": "Até Mês",
            "currentConfigs": "Configurações Atuais",
            "totalSold": "Total Vendido",
            "confirmation": "Upload de Arquivo",
            "confirmUpload": "Deseja fazer upload dos arquivos para o armazém {{warehouse}} do período {{year}}/{{month}}?",
            "confirm": "Confirmar",
            "cancel": "Cancelar",
            "salesByWarehouse": "Vendas por Armazém",
            "topCities": "Principais Cidades",
            "analyze": "Analisar",
            "selectAllWarehouses": "Todos os Armazéns",
            "moreThan" : "Mais que",
            "nameOf": "Nome de",
            "quantitySalesBy" : "Quantidade de Vendas por",
            "filterByNumberOfSales": "Filtrar por quantidade de vendas",
            "filterByName": "Filtrar por nome",
            "all": "Todos",
            "quantity": "Quantidade",
            "sales": "Vendas",
            "percentPrevMonth": "Porcentagem de vendas em relação ao mês anterior",
            "quantityPrevMonth": "Quantidade de vendas em relação ao mês anterior",
            "prevMonthChange": "Variação do mês anterior",
            "gainsAndLosses": "Ganhos e Perdas",
            "statisticsComparison": "Comparação de Estatísticas",
            "compareEachMonthTip": "Compare cada mês com o anterior",
            "gainAndLossesOver": "Ganhos e Perdas sobre ",
            "pnl": "P&L",
            "momFull": "Mês sobre Mês",
            "sold": "Vendido",
            "downloadLocaleSpreadSheet": "Baixar Planilha de Localidades",
            "uploadLocaleSpreadSheet": "Upload Planilha de Localidades",
            "cacheReseted": "Cache resetado com sucesso",
            "reset": "Resetar",
            "cacheResetMessage": "Não está vendo a contagem correta? Tente atualizar seus dados. Isso não alterará nenhum dado anterior ou atual — apenas limpa o armazenamento temporário para ajudar a mostrar as informações corretas.",
            "resetSelectedPeriod": "Resetar Período Selecionado",
            "hardReset": "Resetar Tudo",
            "showProductName": "Mostrar Nome do Produto",
            "showQuantity": "Mostrar Quantidade",
            "showWarehouseName": "Mostrar Nome do Armazém",
            "hideLowQuantity": "Esconder Quantidades Baixas",
            "hideIrregularProducts": "Esconder Produtos Irregulares",
            "overview": "Visão Geral",
            "selectAll": "Selecionar Todos",
            "unselectAll": "Desmarcar Todos",
            "statistics": "Estatísticas",
            "usersAndRoles": "Usuários e Permissões",
        }
    },
    es: {
        translation: {
            "menu": {
                "summary": "Resumen",
                "fileManager": "Archivos",
                "comparison": "Comparación",
                "warehouseConfig": "Almacenes",
                "members": "Usuarios y Permisos",
                "logout": "Salir"
            },
            "salesSummary": "Resumen de Ventas",
            "year": "Año",
            "month": "Mes",
            "months": "Meses",
            "city": "Ciudad",
            "view": "Vista",
            "warehouse": "Almacén",
            "pharmacy": "Farmacia",
            "zipcode": "Código Postal",
            "total": "Total",
            "fileManager": "Gestor de Archivos",
            "selectFiles": "Seleccionar Archivos",
            "selectedFiles": "Archivos Seleccionados",
            "upload": "Subir",
            "files": "Archivos",
            "warehouseSales": "Ventas por Almacén",
            "pharmacySales": "Ventas por Farmacia",
            "productSales": "Ventas por Producto",
            "suggestionsTitle": "Sugerencias",
            "noSuggestions": "No hay sugerencias disponibles.",
            "comparison": "Comparison",
            "fromUntil": "De {{from}} Hasta {{until}}",
            "January": "Enero",
            "February": "Febrero",
            "March": "Marzo",
            "April": "Abril",
            "May": "Mayo",
            "June": "Junio",
            "July": "Julio",
            "August": "Agosto",
            "September": "Septiembre",
            "October": "Octubre",
            "November": "Noviembre",
            "December": "Diciembre",
            "warehouseConfigs": "Configuraciones de Almacenes",
            "addNewConfig": "Agregar Nueva Configuración",
            "name": "Nombre",
            "email": "Correo Electrónico",
            "isAdmin": "Administrador",
            "active": "Activo",
            "accountVerified": "Cuenta Verificada",
            "qtdSheets": "Cantidad de Hojas",
            "productNameColumn": "Columna del Nombre del Producto",
            "pharmacyNameColumn": "Columna del Nombre de la Farmacia",
            "quantitySoldColumn": "Columna de Cantidad Vendida",
            "cityColumn": "Columna de la Ciudad",
            "zipCodeColumn": "Columna del Código Postal",
            "warehouseConfig": "Configuraciones de Almacenes",
            "enterSingleLetter": "Ingrese una sola letra",
            "add": "Agregar",
            "addSuccess": "Configuración agregada con éxito",
            "addError": "Error al agregar configuración",
            "selectPeriod": "Seleccione el Período",
            "salesByProduct": "Ventas por Producto",
            "fromMonth": "Desde Mes",
            "toMonth": "Hasta Mes",
            "currentConfigs": "Configuraciones Actuales",
            "totalSold": "Total Vendido",
            "confirmation": "Subir Archivo",
            "confirmUpload": "¿Desea subir los archivos al almacén {{warehouse}} para el período {{year}}/{{month}}?",
            "confirm": "Confirmar",
            "cancel": "Cancelar",
            "salesByWarehouse": "Ventas por Almacén",
            "topCities": "Principales Ciudades",
            "analyze": "Analizar",
            "selectAllWarehouses": "Todos los Almacenes",
            "moreThan" : "Más que",
            "nameOf": "Nombre de",
            "quantitySalesBy" : "Cantidad de Ventas por",
            "filterByNumberOfSales": "Filtrar por cantidad de ventas",
            "filterByName": "Filtrar por nombre",
            "all": "Todos",
            "quantity": "Cantidad",
            "sales": "Ventas",
            "percentPrevMonth": "Porcentaje de ventas en relación al mes anterior",
            "quantityPrevMonth": "Cantidad de ventas en relación al mes anterior",
            "prevMonthChange": "Variación del mes anterior",
            "gainsAndLosses": "Ganancias y Pérdidas",
            "statisticsComparison": "Comparación de Estadísticas",
            "compareEachMonthTip": "Compare cada mes con el anterior",
            "gainAndLossesOver": "Ganancias y Pérdidas sobre ",
            "pnl": "P&L",
            "momFull": "Mes sobre Mes",
            "sold": "Vendido",
            "downloadLocaleSpreadSheet": "Descargar Hoja de Localidades",
            "uploadLocaleSpreadSheet": "Subir Hoja de Localidades",
            "cacheReseted": "Cache reiniciado con éxito",
            "reset": "Reiniciar",
            "cacheResetMessage": "¿No ves la cuenta correcta? Intenta actualizar tus datos. Esto no cambiará ningún dato anterior o actual — solo borra el almacenamiento temporal para mostrar la información correcta.",
            "resetSelectedPeriod": "Reiniciar Período Seleccionado",
            "hardReset": "Reiniciar Todo",
            "showProductName": "Mostrar Nombre del Producto",
            "showQuantity": "Mostrar Cantidad",
            "showWarehouseName": "Mostrar Nombre del Almacén",
            "hideLowQuantity": "Ocultar Cantidades Bajas",
            "hideIrregularProducts": "Ocultar Productos Irregulares",
            "overview": "Visión General",
            "selectAll": "Seleccionar Todos",
            "unselectAll": "Desmarcar Todos",
            "statistics": "Estadísticas",
            "usersAndRoles": "Usuarios y Permisos",
        }
    }
};

const storedLanguage = localStorage.getItem('i18nextLng') || 'pt';

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: storedLanguage,
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;